import React,{useState,useEffect} from 'react'
import {firebase }from '../Firebase/config';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import moment from 'moment';
const PastDeals = () => {

  const CustomDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
  
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={4}
        stroke={stroke}
        fill={value > 50 ? 'red' : 'green'} // Example of conditional coloring based on value
      />
    );
  };

  const db = firebase.firestore();
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  useEffect(() => {
    const unsubscribe = db.collection('portfolios')
      .where('active', '==', false)
      .onSnapshot((snapshot) => {
        const fetchedPortfolios = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPortfolios(fetchedPortfolios);
      });
  
    return () => unsubscribe();
  }, [db]);
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = portfolios.slice(startIndex, endIndex);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(portfolios.length / itemsPerPage);

  const [showGraph, setShowGraph] = useState(false);
  const [graphData, setGraphData] = useState([]);

  // Function to fetch and prepare data for the graph
  const fetchGraphData = (portfolioId) => {
    const portfolio = portfolios.find((portfolio) => portfolio.id === portfolioId);
    if (portfolio && portfolio.historyvalue) {
      const formattedData = portfolio.historyvalue.map((item) => ({
        datetime: item.datetime,
        value: item.value,
      }));
      setGraphData(formattedData);
      setShowGraph(true);
    }
  };

  const closeGraphModal = () => {
    setShowGraph(false);
  }; 

  if (loading) {
    // Show loading spinner while data is being fetched
    return (
      <div class='flex space-x-2 justify-center items-center bg-white h-screen dark:invert'>
      <span class='sr-only'>Loading...</span>
       <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
     <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
     <div class='h-8 w-8 bg-black rounded-full animate-bounce'></div>
   </div>
    );
  }
  return (
    <div className='min-h-screen' >
       {portfolios.length === 0 && (
        <div className="flex justify-center items-center h-screen">
          <h1 className="text-3xl font-bold text-center">No Past Deals</h1>
        </div>
      )}
    
<div>
   {/* Show Graph */}
{showGraph && (
  <div className="flex flex-col items-center justify-center">
    <div className="w-full md:w-3/4 lg:w-1/2 bg-white rounded-lg shadow-lg">
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Portfolio Graph</h2>
        <div className=" px-4 py-3 flex justify-end">
          <button
            type="button"
            onClick={closeGraphModal}
            className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Close
          </button>
        </div>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <LineChart data={graphData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="datetime" tickFormatter={(tick) => moment(tick).format("M/D/YYYY h:mm:ss A")} /> {/* Format datetime */}
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#FF5733" strokeWidth={2} dot={<CustomDot />} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  </div>
)}

       <table class="min-w-full bg-white font-[sans-serif]">
    <thead class="whitespace-nowrap">
      <tr>
      
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
          Company Name
        </th>
      
       
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
          Value
        </th>
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
          Current Value
        </th>
       
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
         View Graph
        </th>
      
      </tr>
    </thead>
    <tbody class="whitespace-nowrap">
    {portfolios.map((portfolio, index) => (
      <tr key={portfolio.id}  class="odd:bg-blue-50">
        
        <td className="px-6 py-3 text-sm">
        <div className="flex items-center cursor-pointer">
          <div className="ml-4">
            <p className="text-sm text-black">{portfolio.name || '-'}</p>
          </div>
        </div>
      </td>
      
      <td className="px-6 py-3 text-sm">₹ {portfolio.value || '-'}</td>
      <td className="px-6 py-3 text-sm">₹ {portfolio.currentvalue || '-'}</td>
    

     
        <td class="px-6 py-3 text-sm">
        <button onClick={() => fetchGraphData(portfolio.id)}  class="px-4 py-2 bg-blue-500 text-white rounded-lg">View Graph</button>

        </td>
       
        
      </tr>
    ))}
    </tbody>
  </table>
  <div class="fixed lg:ml-64 bottom-0 left-0 right-0 bg-white px-6 py-4 md:flex md:items-center md:justify-between">
  <div className="flex justify-center mt-8">
            <div className="flex space-x-2">
              {/* Back Button */}
              <button
                onClick={() => handlePaginationClick(currentPage - 1)}
                className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
                  currentPage === 1 ? "bg-red-400 cursor-not-allowed" : ""
                }`}
                disabled={currentPage === 1}
              >
                <FiChevronLeft className="inline-block mr-1" /> Previous
              </button>

              {/* Page Buttons */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePaginationClick(index + 1)}
                  className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
                    currentPage === index + 1 ? "bg-red-400" : ""
                  }`}
                >
                  {index + 1}
                </button>
              ))}

              {/* Next Button */}
              <button
                onClick={() => handlePaginationClick(currentPage + 1)}
                className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
                  currentPage === totalPages
                    ? "bg-red-400 cursor-not-allowed"
                    : ""
                }`}
                disabled={currentPage === totalPages}
              >
                Next <FiChevronRight className="inline-block ml-1" />
              </button>
            </div>
          </div>
  </div>

</div>
    </div>
  )
}

export default PastDeals