import React , {useState} from 'react';
import PropTypes from 'prop-types';

import Button from '../button';
import img from '../../assets/images/layout/banner-03.png'

Banner03.propTypes = {
    
};

function Banner03(props) {

    const [dataBlock] = useState(
        {
            title: 'Learn, buy & sell  Pre IPO easily',
            desc : 'Equisculpt is the easiest place to buy and sell  Pre IPO. Sign up and get started today.',
        }
    );
  return (
    <div>
        <section className="relative   bg-white ">


<main className=" relative dark:bg-slate-950  ">
<div className="flex flex-col lg:flex-row lg:pt-4 ">
<section className="w-full lg:w-[50%] flex flex-col lg:translate-x-10 @md:px-2 lg:px-0 p-5 lg:p-10 lg:p-0">
<div className="banner__content">
                    <h2 className="title">{dataBlock.title}</h2>
                    <p style={{marginBottom:16}} className="fs-20 desc">
                        {dataBlock.desc}
                    </p>
                    <Button title='Start trading' path='#' />
                    </div>
</section>
<section className="relative w-full lg:w-[50%] flex  items-center  justify-center  ">
<img src="19197351.jpg" alt="Hero Image" className="h-96 w-96 object-contain z-10"/>
</section>
</div>
</main>
</section>
    </div>
  )
}

export default Banner03;