import React, { useState, useEffect } from 'react';
import { firebase } from './Firebase/config';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import { Route, Routes } from 'react-router-dom';
import Header from './components/header';
import Footer from './components/footer';
import '../src/assets/font/font-awesome.css';
import routes from './pages';
import Page404 from './pages/404';
import { useLocation } from 'react-router-dom';
// http://localhost:3000/signinsinup/?id=jm4GwClNstVBrMJGQwMZ8h9zjtS2
// http://localhost:3000/signinsinup/?id=WAHMnpOcPLZ5gEdLHr5ERXBI7C23
function App() {
  const navigate = useNavigate();


  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);


  return (
    <>
      <Header />
      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
