import React,{useState,useEffect} from 'react'
import {firebase }from '../../Firebase/config';

const Crypto02 = () => {
    const [portfolios, setPortfolios] = useState([]);
    const [loading, setLoading] = useState(false);

    const db = firebase.firestore();
    useEffect(() => {
        // Set loading to true when starting to fetch portfolios
        setLoading(true);
        const unsubscribe = db.collection('portfolios')
          .where('active', '==', true) .where('category', '==', "Trending")
          .onSnapshot((snapshot) => {
            const fetchedPortfolios = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPortfolios(fetchedPortfolios);
            // Set loading to false once portfolios are fetched
            setLoading(false);
          });
    
        return () => unsubscribe();
      }, [db]);
      console.log('portfolios',portfolios)
  return (
    <div>
        <div class="font-[sans-serif] bg-gray-100">
        {loading ? (
      // Show loading indicator
      <div class='flex space-x-2 justify-center items-center bg-white h-screen dark:invert'>
      <span class='sr-only'>Loading...</span>
       <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
     <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
     <div class='h-8 w-8 bg-black rounded-full animate-bounce'></div>
   </div>
    ) : ( 
      <div class="p-4 mx-auto lg:max-w-7xl sm:max-w-full">
        <h2 class="text-4xl font-extrabold text-gray-800 mb-12">Trending IPO</h2>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {portfolios.map((portfolio, index) => (
          <div key={portfolio.id}  class="bg-white rounded-2xl p-6 cursor-pointer hover:-translate-y-2 transition-all relative">
          
            <div class="w-16 h-16 overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-4">
              <img src={portfolio.logo} alt="Product 1" class="h-16 w-16 object-contain" />
            </div>
            <div>
              <h3 class="text-lg font-bold text-gray-800 uppercase text-center">{portfolio.name}</h3>
              <p class="text-gray-500 text-sm mt-2 text-center ">{portfolio.type}</p>
            
            </div>
            <div class="flex justify-between">
    <h4 class="text-lg text-gray-700 font-bold mt-4">Price</h4>
    <h4 class="text-lg text-gray-700 font-bold mt-4">₹ {portfolio.currentvalue}</h4>
</div>

          </div>
        
        ))}
        </div>
      </div>
    )}
    </div>
    </div>
  )
}

export default Crypto02