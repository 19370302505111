

import img1 from '../images/blog/blog-01.jpg';
import img2 from '../images/blog/blog-02.jpg';

const dataBlog = [
    {
        id: 1,
        img: img1,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 2,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 3,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
        id: 4,
        img: img1,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021'
    },
    {
        id: 5,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021'
    },
    {
        id: 6,
        img: img2,
        category: 'learn & earn',
        title: 'Learn about UI8 coin and earn an All-Access Pass',
        user:'Floyd Buckridge',
        time: 'Feb 03, 2021'
    },
   
]

export default dataBlog;