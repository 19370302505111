import React,{useState,useEffect} from 'react'
import {firebase }from '../Firebase/config';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const CurrentDeals = () => {

  const CustomDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
  
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={4}
        stroke={stroke}
        fill={value > 50 ? 'red' : 'green'} // Example of conditional coloring based on value
      />
    );
  };

  const db = firebase.firestore();
  const storage = firebase.storage();
  const [portfolios, setPortfolios] = useState([]);
  const [currentPortfolioId, setCurrentPortfolioId] = useState(null);
  const [showModal, setShowModal] = useState(false);  
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [step, setStep] = useState(1);
  const [result, setResult] = useState(null);

  const itemsPerPage = 9;


  const [users, setUsers] = useState(null);
   
  useEffect(() => {
      // Get the current user
      const currentUser = firebase.auth().currentUser;
    
      if (currentUser) {
        // Fetch user data from Firestore using the UID
        firebase.firestore().collection('users').doc(currentUser.uid).get()
          .then((doc) => {
            if (doc.exists) {
              // Set the userData state with the retrieved data and id
              setUserData({ ...doc.data(), id: doc.id });
            } else {
              console.log('No such document!');
            }
          })
          .catch((error) => {
            console.error('Error getting document:', error);
          });
      }
    }, []);
    console.log("users",userData)

  useEffect(() => {
    // Set loading to true when starting to fetch portfolios
    setLoading(true);
    const unsubscribe = db.collection('portfolios')
      .where('active', '==', true)
      .onSnapshot((snapshot) => {
        const fetchedPortfolios = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPortfolios(fetchedPortfolios);
        // Set loading to false once portfolios are fetched
        setLoading(false);
      });

    return () => unsubscribe();
  }, [db]);
  console.log('portfolios',portfolios)
  const openModal = (portfolioId) => {
    setShowModal(true);
    setCurrentPortfolioId(portfolioId);
  };
  
  const closeModal = () => {
    setShowModal(false);
    setCurrentPortfolioId(null);
  };
  
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = portfolios.slice(startIndex, endIndex);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(portfolios.length / itemsPerPage);

  const [showGraph, setShowGraph] = useState(false);
  const [graphData, setGraphData] = useState([]);

  // Function to fetch and prepare data for the graph
  const fetchGraphData = (portfolioId) => {
    const portfolio = portfolios.find((portfolio) => portfolio.id === portfolioId);
    if (portfolio && portfolio.historyvalue) {
      const formattedData = portfolio.historyvalue.map((item) => ({
        datetime: item.datetime,
        value: item.value,
      }));
      setGraphData(formattedData);
      setShowGraph(true);
    }
  };

  const closeGraphModal = () => {
    setShowGraph(false);
  }; 

  const openPDF = (FINANCIALPDF) => {
    window.open(FINANCIALPDF, '_blank');
  };
  const openPDFs = (PITCHDECKPDF) => {
    window.open(PITCHDECKPDF, '_blank');
  };





  const navigate = useNavigate();



  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState({
    nooflots: '',
  });
  


  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handlePanCardUpload = (e) => {
    const panimage = e.target.files[0];
    if (panimage.size > 50 * 1024) { // Check if file size is greater than 50kb
      toast.error('Pan Card File size exceeds 50kb limit!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution
    }
    setUserData({ ...userData, panCardUrl: panimage });
  };
  
  const handleAadharCardUpload = (e) => {
    const aadharimage = e.target.files[0];
    if (aadharimage.size > 50 * 1024) { // Check if file size is greater than 50kb
      toast.error('Aadhar Card File size exceeds 50kb limit!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution
    }
    setUserData({ ...userData, aadharCardUrl: aadharimage });
  };
  
  const handleCancelledCheckUpload = (e) => {
    const cancelledcheckimage = e.target.files[0];
    if (cancelledcheckimage.size > 50 * 1024) { // Check if file size is greater than 50kb
      toast.error('Cancelled Check File size exceeds 50kb limit!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution
    }
    setUserData({ ...userData, cancelledcheckUrl: cancelledcheckimage });
  };
  
  const handleCMRCopyUpload = (e) => {
    const cmrcopyimage = e.target.files[0];
    if (cmrcopyimage.size > 50 * 1024) { // Check if file size is greater than 50kb
      toast.error('CMR Copy File size exceeds 50kb limit!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Stop further execution
    }
    setUserData({ ...userData, cmrcopyUrl: cmrcopyimage });
  };
  const [myuserData, setMyUserData] = useState(null);
  const currentUser = firebase.auth().currentUser;
  
  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      const unsubscribe = db.collection('users').doc(currentUser.uid).collection('Assign_Bank_Details')
        .onSnapshot((snapshot) => {
          const fetchedPortfolios = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setMyUserData(fetchedPortfolios);
        });
      
      return () => unsubscribe();
    }
  }, [db]);
  
  useEffect(() => {
    console.log(myuserData); // Log myuserData after it has been updated
  }, [myuserData]); // Trigger this effect whenever myuserData changes
  
  // ...
  
  const [priceuserData, setPriceUserData] = useState(null);
   
  useEffect(() => {
      // Get the current user
      const currentUser = firebase.auth().currentUser;
    
      if (currentUser) {
        // Fetch user data from Firestore using the UID
        firebase.firestore().collection('users').doc(currentUser.uid).get()
          .then((doc) => {
            if (doc.exists) {
              // Set the userData state with the retrieved data and id
              setPriceUserData({ ...doc.data(), id: doc.id });
            } else {
              console.log('No such document!');
            }
          })
          .catch((error) => {
            console.error('Error getting document:', error);
          });
      }
    }, []);
    console.log(priceuserData)
      // Calculate the result
      const earnPrice = priceuserData && priceuserData.earnPrice ? parseFloat(priceuserData.earnPrice) : 0;

console.log(earnPrice)

  const handleNextStep = () => {
    // Ensure userData.nooflots is parsed as a number
    const numberOfLots = parseFloat(userData.nooflots);
  
    // Find the current portfolio
    const currentPortfolio = portfolios.find(portfolio => portfolio.id === currentPortfolioId);
    
    if (currentPortfolio) {
      // Get the current value from the portfolio
      const currentValue = parseFloat(currentPortfolio.currentvalue);
  
      // Check if both values are valid numbers
      if (!isNaN(numberOfLots) && !isNaN(currentValue)) {
        // Get earnPrice from userData or default to 0 if not available
        // const earnPrice = priceuserData.earnprice ? parseFloat(priceuserData.earnprice) : 0;
        
        // Calculate the result
        const calculatedResult = numberOfLots * currentValue;
        setResult(calculatedResult);
        setStep(2);
      } else {
        // Handle the case where either value is not a valid number
        console.error('Invalid number of lots or current value');
        // Optionally, you can display an error message to the user
        // toast.error('Invalid number of lots or current value');
      }
    } else {
      // Handle the case where the current portfolio is not found
      console.error('Current portfolio not found');
      // Optionally, you can display an error message to the user
      // toast.error('Current portfolio not found');
    }
  };
  
  const storageRef = firebase.storage().ref();
  const handleSubmitStep2 = async () => {
    try {
      setIsLoading(true);
  
      const portfolio = portfolios.find((portfolio) => portfolio.id === currentPortfolioId);
      // Check if portfolio is found
      if (portfolio) {
        // Proceed with submission using portfolio data
        await submitPortfolioData(portfolio);
      } else {
        // Handle error if portfolio is not found
        toast.error('Portfolio not found!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error('Error submitting data:', error);
      toast.error('Failed to submit data!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }
  };
  



  
  const submitPortfolioData = async (portfolio) => {
    try {
      const { nooflots } = userData;
  
    
  
    
  
      // Construct data object to be stored in Firestore
      const userDataToStore = {
        nooflots,
        useremail: currentUser.email,
        totalpay: result,
        status: 'Pending',
        portfolioid: portfolio.id,
        portfolioname: portfolio.name,
        portfoliovalue: portfolio.currentvalue,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
  
      // Add data to Firestore
      const orderRef = await db.collection('orders').add(userDataToStore);
  
      // Update the Rewarduse field in the users collection
      // await db.collection('users').doc(currentUser.uid).update({
      //   Rewarduse: firebase.firestore.FieldValue.arrayUnion({
      //     orderID: orderRef.id,
      //     usePrice: earnPrice,
       
      //   }),
      // });
  
      // Update totalshare in the portfolios collection
      const updatedTotalShare = parseInt(portfolio.totalshare) - parseInt(nooflots); // Calculate updated totalshare
      await db.collection('portfolios').doc(portfolio.id).update({ 
        purchaseshare: firebase.firestore.FieldValue.increment(updatedTotalShare) 
    });
    
  
      // Show success message
      toast.success('Your Data submitted successfully!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
  
      // Reset form data
      setSuccess(true);
      setIsLoading(false);
      setShowModal(false);
      setUserData({
        nooflots: '',
      });
    } catch (error) {
      // Handle any errors that occur during the submission process
      console.error('Error submitting data:', error);
      toast.error('Failed to submit data!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  
  if (loading) {
    // Show loading spinner while data is being fetched
    return (
      <div class='flex space-x-2 justify-center items-center bg-white h-screen dark:invert'>
      <span class='sr-only'>Loading...</span>
       <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
     <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
     <div class='h-8 w-8 bg-black rounded-full animate-bounce'></div>
   </div>
    );
  }
  
  
      
  return (
    <div className='min-h-screen' >
       {portfolios.length === 0 && (
        <div className="flex justify-center items-center h-screen">
          <h1 className="text-3xl font-bold text-center">No Current Deals</h1>
        </div>
      )}
     
<div>
   {/* Show Graph */}
{showGraph && (
  <div className="flex flex-col items-center justify-center">
    <div className="w-full md:w-3/4 lg:w-1/2 bg-white rounded-lg shadow-lg">
      <div className="p-4">
        <h2 className="text-xl font-bold mb-4">Portfolio Graph</h2>
        <div className=" px-4 py-3 flex justify-end">
          <button
            type="button"
            onClick={closeGraphModal}
            className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Close
          </button>
        </div>
        <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <LineChart data={graphData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="datetime" tickFormatter={(tick) => moment(tick).format("M/D/YYYY h:mm:ss A")} /> {/* Format datetime */}
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#FF5733" strokeWidth={2} dot={<CustomDot />} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  </div>
)}


{showModal && (
  <div className="fixed z-10 inset-0 overflow-y-auto">
    <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      {/* Background overlay */}
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      {/* Modal content */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:max-w-lg sm:w-full">
        {/* Modal content goes here */}
       
      {success && (
        <div className="bg-green-200 text-green-800 p-3 rounded mt-3">
          Data submitted successfully!
        </div>
      )}
       
<section class="max-w-full p-6 mx-auto bg-gray-700 rounded-md shadow-md dark:bg-gray-800 mt-4">
{step === 1 && (
    <><h1 class="text-xl font-bold text-white capitalize dark:text-white">Enter Details</h1><div>
                                                  <div class="mt-2">
                                                     
                                                      <div>
                                                          <label class="text-white dark:text-gray-200" for="username">No. of Lots</label>
                                                          <input id="nooflots" type="text" name="nooflots"
                                                              value={userData.nooflots}
                                                              required
                                                              onChange={handleChange} class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring" />
                                                      </div>
                                                    
                                                  



                                                  </div>

                                                  <div class="flex justify-end mt-6">
                                                      <button
                                                          onClick={handleNextStep}
                                                          className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-green-500 rounded-md hover:bg-green-700 focus:outline-none focus:bg-gray-600"
                                                          disabled={isloading}
                                                      >
                                                          {isloading ? "Submitting..." : "Save"}
                                                      </button>

                                                      <button type="button" onClick={closeModal} className="px-6 ml-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-red-500 rounded-md hover:bg-red-700 focus:outline-none focus:bg-gray-600">
                                                          Close
                                                      </button>
                                                  </div>
                                              </div></>
)}
{step === 2 && (
  <div className='mt-8 md:mt-0'>
    <h1 className="text-sm  font-bold text-white uppercase text-center text-justify dark:text-white">Please {userData.name} make this payment in these bank account details & Submit</h1>
    <div>
      {/* Map through myuserData and render the bank account details */}
      {myuserData && myuserData.map((bankAccount, index) => (
        <div key={index} className="mx-auto max-w-lg ">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg">
            <div className="px-6 py-4">
              <div className="flex justify-between items-center">
                <img className="h-8" src="https://www.svgrepo.com/show/499847/company.svg" alt="Workflow logo"/>
                {bankAccount.ifsccode && (
  <span className="font-medium text-gray-600">IFSC CODE: {bankAccount.ifsccode}</span>
)}
{!bankAccount.ifsccode && (
  <span>IFSC CODE: SBIN1500236</span>
)}

              </div>
              <div className="mt-4">
              {bankAccount.BankName ? (
  <div className="font-bold text-gray-800 text-md md:text-xl">Bank Name: {bankAccount.BankName}</div>
) : (
  <span>Bank Name: State bank of India</span>
)}

{bankAccount.AccountNo ? (
  <div className="font-bold text-gray-800 text-md md:text-xl">Ac.No: {bankAccount.AccountNo}</div>
) : (
  <span>Ac.No: 420124563225</span>
)}

{bankAccount.AccountHolderName ? (
  <div className="font-bold text-gray-800 text-md md:text-xl">Bank Holder NAME: {bankAccount.AccountHolderName}</div>
) : (
  <span>Bank Holder NAME: ROHIT KUMAR</span>
)}

              </div>
            </div>
            <div className="bg-gray-100 px-6 py-4">
              <div className="font-medium text-gray-600">Pay You Amount</div>
              <div className="text-lg font-bold text-gray-800 mt-2">₹ {result}</div>
            </div>
            <div className="flex justify-end px-4 py-6">
              <button
                onClick={handleSubmitStep2}
                className="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-green-500 rounded-md hover:bg-green-700 focus:outline-none focus:bg-gray-600"
                disabled={isloading}
              >
                {isloading ? "Submitting..." : "Pay & Submit"}
              </button>
              <button type="button" onClick={closeModal} className="px-6 ml-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-red-500 rounded-md hover:bg-red-700 focus:outline-none focus:bg-gray-600">
                Close
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}

</section>

        
      
      </div>
    </div>
  </div>
)}
<div className="overflow-x-auto">

       <table class="min-w-full bg-white font-[sans-serif]">
    <thead class="whitespace-nowrap">
      <tr>
      
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
          Company Name
        </th>
        {/* <th class="px-6 py-3 text-left text-sm font-semibold text-black">
          Role
          <svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 fill-gray-400 inline cursor-pointer ml-2"
            viewBox="0 0 401.998 401.998">
            <path
              d="M73.092 164.452h255.813c4.949 0 9.233-1.807 12.848-5.424 3.613-3.616 5.427-7.898 5.427-12.847s-1.813-9.229-5.427-12.85L213.846 5.424C210.232 1.812 205.951 0 200.999 0s-9.233 1.812-12.85 5.424L60.242 133.331c-3.617 3.617-5.424 7.901-5.424 12.85 0 4.948 1.807 9.231 5.424 12.847 3.621 3.617 7.902 5.424 12.85 5.424zm255.813 73.097H73.092c-4.952 0-9.233 1.808-12.85 5.421-3.617 3.617-5.424 7.898-5.424 12.847s1.807 9.233 5.424 12.848L188.149 396.57c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428l127.907-127.906c3.613-3.614 5.427-7.898 5.427-12.848 0-4.948-1.813-9.229-5.427-12.847-3.614-3.616-7.899-5.42-12.848-5.42z"
              data-original="#000000" />
          </svg>
        </th> */}
        
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
          Value
        </th>
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
          Current Value
        </th>
       
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
         View Graph
        </th>
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
         View Pitchdeck Doc
        </th>
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
         View Financial Doc
        </th>
        <th class="px-6 py-3 text-left text-sm font-semibold text-black">
         Action
        </th>
      
      </tr>
    </thead>
    <tbody class="whitespace-nowrap">
    {portfolios.map((portfolio, index) => (
      <tr key={portfolio.id}  class="odd:bg-blue-50">
        
        <td className="px-6 py-3 text-sm">
        <div className="flex items-center cursor-pointer">
          <div className="ml-4">
            <p className="text-sm text-black">{portfolio.name || '-'}</p>
          </div>
        </div>
      </td>
      
      <td className="px-6 py-3 text-sm">₹ {portfolio.value || '-'}</td>
      <td className="px-6 py-3 text-sm">₹ {portfolio.currentvalue || '-'}</td>
    

     
        <td class="px-6 py-3 text-sm">
        <button onClick={() => fetchGraphData(portfolio.id)}  class="px-4 py-2 bg-blue-500 text-white rounded-lg">View Graph</button>

        </td>
        <td class="px-6 py-3 text-sm">
        <button onClick={() => openPDFs(portfolio.PITCHDECKPDF)}  class="px-4 py-2 bg-blue-500 text-white rounded-lg">View Documents</button>

        </td>
        <td class="px-6 py-3 text-sm">
        <button onClick={() => openPDF(portfolio.FINANCIALPDF)}  class="px-4 py-2 bg-blue-500 text-white rounded-lg">View Documents</button>

        </td>
        <td class="px-6 py-3 text-sm">
        <button onClick={() => openModal(portfolio.id)} className="px-4 py-2 bg-blue-500 text-white rounded-lg">
  Commit
</button>
</td>

       
        
      </tr>
    ))}
    </tbody>
  </table>
  </div>
  <div className="flex justify-center mt-8">
            <div className="flex space-x-2">
              {/* Back Button */}
              <button
                onClick={() => handlePaginationClick(currentPage - 1)}
                className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
                  currentPage === 1 ? "bg-red-400 cursor-not-allowed" : ""
                }`}
                disabled={currentPage === 1}
              >
                <FiChevronLeft className="inline-block mr-1" /> Previous
              </button>

              {/* Page Buttons */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePaginationClick(index + 1)}
                  className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
                    currentPage === index + 1 ? "bg-red-400" : ""
                  }`}
                >
                  {index + 1}
                </button>
              ))}

              {/* Next Button */}
              <button
                onClick={() => handlePaginationClick(currentPage + 1)}
                className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
                  currentPage === totalPages
                    ? "bg-red-400 cursor-not-allowed"
                    : ""
                }`}
                disabled={currentPage === totalPages}
              >
                Next <FiChevronRight className="inline-block ml-1" />
              </button>
            </div>
          </div>

</div>
       
       <ToastContainer />
    </div>
  )
}

export default CurrentDeals