

import img1 from '../images/avt/use-01.png';
import img2 from '../images/avt/use-02.png';
import img3 from '../images/avt/use-03.png';
import img4 from '../images/avt/use-04.png';
import img5 from '../images/avt/use-05.png';
import img6 from '../images/avt/use-06.png';

import logo from '../images/partner/logo-06.png';



const dataTestimonial2 = [
    {
        id: 1,
        title: '“Trading crypto has never been so easy.”',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non viverra eros purus. Turpis ac scelerisque blandit mauris ut senectus nibh. Pretium cras sit non at amet velit id in.',
        avt: img1,
        name: 'Marvin McKinney',
        position: 'CEO of Startup',
        partner: logo
    },
    {
        id: 2,
        title: '“Trading crypto has never been so easy.”',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non viverra eros purus. Turpis ac scelerisque blandit mauris ut senectus nibh. Pretium cras sit non at amet velit id in.',
        avt: img2,
        name: 'Marvin McKinney',
        position: 'CEO of Startup',
        partner: logo
    },
    {
        id: 3,
        title: '“Trading crypto has never been so easy.”',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non viverra eros purus. Turpis ac scelerisque blandit mauris ut senectus nibh. Pretium cras sit non at amet velit id in.',
        avt: img3,
        name: 'Marvin McKinney',
        position: 'CEO of Startup',
        partner: logo
    },
    {
        id: 4,
        title: '“Trading crypto has never been so easy.”',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non viverra eros purus. Turpis ac scelerisque blandit mauris ut senectus nibh. Pretium cras sit non at amet velit id in.',
        avt: img4,
        name: 'Marvin McKinney',
        position: 'CEO of Startup',
        partner: logo
    },
    {
        id: 5,
        title: '“Trading crypto has never been so easy.”',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non viverra eros purus. Turpis ac scelerisque blandit mauris ut senectus nibh. Pretium cras sit non at amet velit id in.',
        avt: img5,
        name: 'Marvin McKinney',
        position: 'CEO of Startup',
        partner: logo
    },
    {
        id: 6,
        title: '“Trading crypto has never been so easy.”',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mi non viverra eros purus. Turpis ac scelerisque blandit mauris ut senectus nibh. Pretium cras sit non at amet velit id in.',
        avt: img6,
        name: 'Marvin McKinney',
        position: 'CEO of Startup',
        partner: logo
    },
   
]

export default dataTestimonial2;