import React, { useState, useEffect } from 'react';
import { firebase } from '../../Firebase/config';

const Trending = () => {
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(false);

  const db = firebase.firestore();

  useEffect(() => {
    // Set loading to true when starting to fetch portfolios
    setLoading(true);
    const unsubscribe = db.collection('portfolios')
      .where('active', '==', true)
      .where('category', '==', "Recent")
      .onSnapshot((snapshot) => {
        const fetchedPortfolios = snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            secondLastHistoryValue: data.historyvalue[data.historyvalue.length - 2]?.value || null
          };
        });
        setPortfolios(fetchedPortfolios);
        // Set loading to false once portfolios are fetched
        setLoading(false);
      });

    return () => unsubscribe();
  }, [db]);

  console.log('portfolios trending', portfolios);

  return (
    <div>
      <div className="font-[sans-serif] bg-gray-100">
        {loading ? (
          // Show loading indicator
          <div className='flex space-x-2 justify-center items-center bg-white h-screen dark:invert'>
            <span className='sr-only'>Loading...</span>
            <div className='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
            <div className='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
            <div className='h-8 w-8 bg-black rounded-full animate-bounce'></div>
          </div>
        ) : (
          <div className="p-4 mx-auto lg:max-w-7xl sm:max-w-full">
            <h2 className="text-4xl font-extrabold text-gray-800 mb-2">Recent Pre IPO</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {portfolios.map((portfolio, index) => (
                <div key={portfolio.id} className="bg-white rounded-2xl p-6 cursor-pointer hover:-translate-y-2 transition-all relative">
                  <div className="w-16 h-16 overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-4">
                    <img src={portfolio.logo} alt="Product 1" className="h-16 w-16 object-contain" />
                  </div>
                  <div>
                    <h3 className="text-lg font-bold text-gray-800 uppercase text-center">{portfolio.name}</h3>
                    <p className="text-gray-500 text-sm mt-2 text-center ">{portfolio.type}</p>
                  </div>
                  <div className="flex justify-between">
                    <h4 className="text-lg text-gray-700 font-bold mt-4">Price</h4>
                    <h4 className="text-lg text-gray-700 font-bold mt-4">₹ {portfolio.currentvalue}</h4>
                  </div>
                  <div className="flex justify-between">
                    <h4 className="text-lg text-gray-700 font-bold mt-4">Previous Close Value</h4>
                    <h4 className="text-lg text-gray-700 font-bold mt-4">₹ {portfolio.secondLastHistoryValue}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Trending;
