import React, { useState, useEffect } from 'react';
import { firebase } from '../Firebase/config';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Dot } from 'recharts';
import moment from 'moment';

const Portfolio = () => {
  const CustomDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
  
    return (
      <Dot
        cx={cx}
        cy={cy}
        r={4}
        stroke={stroke}
        fill={value > 50 ? 'red' : 'green'} // Example of conditional coloring based on value
      />
    );
  };

  const db = firebase.firestore();
  const [portfolios, setPortfolios] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [portfoliosData, setPortfoliosData] = useState([]);
  const [portfoliosid, setPortfoliosid] = useState([]);
  const [portfoliosstatus, setPortfoliosstatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  
  useEffect(() => {
    const currentUser = firebase.auth().currentUser;
    const unsubscribe = db.collection('orders')
      .where('useremail', '==', currentUser.email)
      .onSnapshot(snapshot => {
        const fetchedPortfoliosData = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            portfolioid: data.portfolioid,
            ...data,
          };
        });
        setPortfoliosData(fetchedPortfoliosData);
        const fetchedPortfoliosIds = fetchedPortfoliosData.map((item) => item.portfolioid);
        setPortfoliosid(fetchedPortfoliosIds);
        const fetchedPortfoliosstattus = fetchedPortfoliosData.map((item) => item.status);
        setPortfoliosstatus(fetchedPortfoliosstattus);
        setLoading(false); // Set loading to false after data is fetched
      });

    return () => unsubscribe();
  }, [db]);

  useEffect(() => {
    const unsubscribe = db.collection('portfolios')
      .where('active', '==', true)
      .onSnapshot((snapshot) => {
        const fetchedPortfolios = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPortfolios(fetchedPortfolios);
      });

    return () => unsubscribe();
  }, [db]);

  const groupedPortfolios = Object.values(
    portfoliosData.reduce((acc, portfolio) => {
      const key = portfolio.portfolioname;
      if (!acc[key]) {
        acc[key] = { portfolioname: portfolio.portfolioname, totalPortfolioValue: 0, totalNoOfLots: 0, count: 0 ,status: portfolio.status };
      }
      acc[key].count++;
      acc[key].totalNoOfLots += parseInt(portfolio.nooflots);
      acc[key].totalPortfolioValue += parseInt(portfolio.portfoliovalue);
      return acc;
    }, {})
  );

  // Calculate average portfolio value and invested value for each portfolio
  groupedPortfolios.forEach(portfolio => {
    portfolio.averagePortfolioValue = portfolio.totalPortfolioValue / portfolio.count;
    portfolio.averageNoOfLots = portfolio.totalNoOfLots;
    portfolio.investedValue = portfolio.averagePortfolioValue * portfolio.averageNoOfLots;
    const matchedPortfolio = portfolios.find(p => p.name === portfolio.portfolioname);
    if (matchedPortfolio) {
      portfolio.currentPerShareValue = matchedPortfolio.currentvalue;
      portfolio.currentValue = portfolio.averageNoOfLots * matchedPortfolio.currentvalue; // Calculate current value
      portfolio.overallGain = portfolio.currentValue - portfolio.investedValue; // Calculate overall gain
      portfolio.overallGainPercentage = ((portfolio.overallGain / portfolio.investedValue) * 100).toFixed(2); // Calculate overall gain/loss percentage
    } else {
      portfolio.currentPerShareValue = 0; // Default value if portfolio not found
      portfolio.currentValue = 0; // Default value if portfolio not found
      portfolio.overallGain = 0; // Default value if portfolio not found
      portfolio.overallGainPercentage = 0; // Default value if portfolio not found
    }
  });

  // Calculate total invested value and total current value across all portfolios
  const totalInvestedValue = groupedPortfolios.reduce((acc, portfolio) => acc + portfolio.investedValue, 0);
  const totalCurrentValue = groupedPortfolios.reduce((acc, portfolio) => acc + portfolio.currentValue, 0);
  const totalRealizedGainLoss = totalCurrentValue - totalInvestedValue;
  const totalUnrealizedGainLoss = totalCurrentValue - totalInvestedValue - totalRealizedGainLoss;

  const [showGraph, setShowGraph] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [totalGraphData, setTotalGraphData] = useState([]);

  const closeGraphModal = () => {
    setShowGraph(false);
  }; 

  // Function to fetch and prepare data for the portfolio graph
  const fetchGraphData = (portfolioname) => {
    const portfolio = portfolios.find((portfolio) => portfolio.name === portfolioname);
    if (portfolio && portfolio.historyvalue) {
      const formattedData = portfolio.historyvalue.map((item) => ({
        datetime: item.datetime,
        value: item.value,
      }));
      setGraphData(formattedData);
      setShowGraph(true);
    }
  };

  // Function to prepare data for the total graph
  const [showTotalGraph, setShowTotalGraph] = useState(false);

  // Function to prepare data for the total graph
  const prepareTotalGraphData = () => {
    const totalGraphData = [
      { name: 'Total Invested Value', value: totalInvestedValue },
      { name: 'Total Current Value', value: totalCurrentValue }
    ];
    setTotalGraphData(totalGraphData);
  };

  const closeTotalGraphModal = () => {
    setShowTotalGraph(false);
  }; 

  useEffect(() => {
    prepareTotalGraphData();
  }, [portfolios]);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = portfolios.slice(startIndex, endIndex);

  const handlePaginationClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(portfolios.length / itemsPerPage);
  if (loading) {
    // Show loading spinner while data is being fetched
    return (
      <div class='flex space-x-2 justify-center items-center bg-white h-screen dark:invert'>
        <span class='sr-only'>Loading...</span>
        <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
        <div class='h-8 w-8 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
        <div class='h-8 w-8 bg-black rounded-full animate-bounce'></div>
      </div>
    );
  }
  return (
    <div className='min-h-screen'>
      {portfoliosData.length === 0 && (
        <div className="flex justify-center items-center h-screen">
          <h1 className="text-3xl font-bold text-center">No Portfolio purchase, please start IPO</h1>
        </div>
      )}

      {showGraph && (
        <div className="flex flex-col items-center justify-center">
          <div className="w-full bg-black md:w-3/4 lg:w-1/2  rounded-lg shadow-lg">
            <div className="px-4">
              <div className=" px-4 py-3 flex text-white justify-between">
                <h2 className="text-xl font-bold mb-4 text-white">Portfolio Graph</h2>
                <button
                  type="button"
                  onClick={closeGraphModal}
                  className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Close
                </button>
              </div>
              <div style={{ width: '100%', height: 400 }}>
                <ResponsiveContainer>
                  <LineChart data={graphData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="datetime" tickFormatter={(tick) => moment(tick).format("M/D/YYYY h:mm:ss A")} /> {/* Format datetime */}
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="value" stroke="#FF5733" strokeWidth={4} dot={<CustomDot />} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      )}


{showTotalGraph && (
        <div className="flex flex-col items-center justify-center">
          <div className="w-full bg-black md:w-3/4 lg:w-1/2  rounded-lg shadow-lg">
            <div className="px-4">
              <div className=" px-4 py-3 flex text-white justify-between">
                <h2 className="text-xl font-bold mb-4 text-white">Total Portfolio Graph</h2>
                <button
                  type="button"
                  onClick={closeTotalGraphModal}
                  className="inline-block bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                  Close
                </button>
              </div>
              <div style={{ width: '100%', height: 400 }}>
                <ResponsiveContainer>
                  <LineChart data={totalGraphData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="value" stroke="#FF5733" strokeWidth={4} dot={<CustomDot />} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* New table for total portfolio values */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white font-[sans-serif] mb-8">
          <thead className="whitespace-nowrap">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Total Invested Value</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Total Current Value</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Realized Gain/Loss</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Unrealized Gain/Loss</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Graph</th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            <tr>
              <td className="px-6 py-3 text-sm">₹ {totalInvestedValue}</td>
              <td className="px-6 py-3 text-sm">₹ {totalCurrentValue}</td>
              <td className={`px-6 py-3 font-bold text-sm ${totalRealizedGainLoss < 0 ? 'text-red-500' : 'text-green-500'}`}>
                ₹ {totalRealizedGainLoss} ({((totalRealizedGainLoss / totalInvestedValue) * 100).toFixed(2)}%)
              </td>
              <td className={`px-6 py-3 font-bold text-sm ${totalUnrealizedGainLoss < 0 ? 'text-red-500' : 'text-green-500'}`}>
                ₹ {totalUnrealizedGainLoss} ({((totalUnrealizedGainLoss / totalInvestedValue) * 100).toFixed(2)}%)
              </td>
              <td className="px-2 py-1 text-xs">
                <button  onClick={() => setShowTotalGraph(true)} className="px-2 py-1 bg-blue-500 text-white rounded-sm">View Graph</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Existing table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white font-[sans-serif]">
          <thead className="whitespace-nowrap">
            <tr>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Company Name</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">No of Share</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Average Portfolio Value</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Invested Value</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Current Per Share Value</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Current Value</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Order Status</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Overall Gain/Loss(%)</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Graph</th>
              <th className="px-6 py-3 text-left text-sm font-semibold text-black">Sell</th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {groupedPortfolios.map((portfolio, index) => (
              <tr key={index} className="odd:bg-blue-50">
                <td className="px-6 py-3 text-sm">{portfolio.portfolioname || '-'}</td>
                <td className="px-6 py-3 text-sm">{portfolio.averageNoOfLots}</td>
                <td className="px-6 py-3 text-sm">₹ {portfolio.averagePortfolioValue}</td>
                <td className="px-6 py-3 text-sm">₹ {portfolio.investedValue}</td>
                <td className="px-6 py-3 text-sm">₹ {portfolio.currentPerShareValue}</td>
                <td className="px-6 py-3 text-sm">₹ {portfolio.currentValue}</td>
                <td className="px-6 py-3 text-sm">{portfolio.status || '-'}</td>
                <td className={`px-6 py-3 font-bold text-sm ${portfolio.overallGain < 0 ? 'text-red-500' : 'text-green-500'}`}>
                  ₹ {portfolio.overallGain} ({portfolio.overallGainPercentage}%) {portfolio.overallGain < 0 ? '↓' : '↑'}
                </td>
                <td className="px-2 py-1 text-xs">
                  <button onClick={() => fetchGraphData(portfolio.portfolioname)} className="px-2 py-1 bg-blue-500 text-white rounded-sm">View Graph</button>
                </td>
                <td className="px-2 py-1 text-xs">
                  <button className="px-2 py-1 bg-red-500 text-white rounded-sm">Sell</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-8">
        <div className="flex space-x-2">
          {/* Back Button */}
          <button
            onClick={() => handlePaginationClick(currentPage - 1)}
            className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
              currentPage === 1 ? "bg-red-400 cursor-not-allowed" : ""
            }`}
            disabled={currentPage === 1}
          >
            <FiChevronLeft className="inline-block mr-1" /> Previous
          </button>

          {/* Page Buttons */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePaginationClick(index + 1)}
              className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
                currentPage === index + 1 ? "bg-red-400" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}

          {/* Next Button */}
          <button
            onClick={() => handlePaginationClick(currentPage + 1)}
            className={`px-4 py-2 text-sm text-white font-medium bg-red-300 rounded-md ${
              currentPage === totalPages
                ? "bg-red-400 cursor-not-allowed"
                : ""
            }`}
            disabled={currentPage === totalPages}
          >
            Next <FiChevronRight className="inline-block ml-1" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
