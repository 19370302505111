import React from 'react';
import Button from '../components/button';


function Page404(props) {
    return (
404
    );
}

export default Page404;