import React, { useEffect, useState } from 'react';
import Sale01 from '../components/sale/Sale01';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PageTitle from '../components/pagetitle';
import {Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { firebase } from '../Firebase/config';
UserProfile.propTypes = {
    
};

function UserProfile(props) {

    const [dataCoinTab] = useState([
        {
            id: 1,
            title: 'User Profile',
            icon: 'fa-user'
        },
        {
            id: 2,
            title: 'Referrals',
            icon: 'fa-share-nodes'
        },
        // {
        //     id: 3,
        //     title: 'Fund Transaction',
        //     icon: 'fa-barcode'
        // },
        // {
        //     id: 4,
        //     title: '2FA',
        //     icon: 'fa-barcode'
        // },
        // {
        //     id: 5,
        //     title: 'Change password',
        //     icon: 'fa-lock'
        // },
        {
            id: 5,
            title: 'Logout',
            icon: 'fa-sign-out-alt'
        },

    ]);
    const navigate = useNavigate();

    const [userData, setUserData] = useState(null);
   
    useEffect(() => {
        // Get the current user
        const currentUser = firebase.auth().currentUser;
      
        if (currentUser) {
          // Fetch user data from Firestore using the UID
          firebase.firestore().collection('users').doc(currentUser.uid).get()
            .then((doc) => {
              if (doc.exists) {
                // Set the userData state with the retrieved data and id
                setUserData({ ...doc.data(), id: doc.id });
              } else {
                console.log('No such document!');
              }
            })
            .catch((error) => {
              console.error('Error getting document:', error);
            });
        }
      }, []);
      console.log(userData)

    const [refferalData, setRefferalData] = useState([]);

    useEffect(() => {
        const currentUser = firebase.auth().currentUser;
    
        const fetchData = async () => {
            try {
                if (currentUser) {
                    const userCollection = await firebase
                        .firestore()
                        .collection('users')
                        .where('referralId', '==', currentUser.uid)
                        .get();
    
                    // Extracting data from documents in the collection
                    const referralDataArray = userCollection.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
    
                    setRefferalData(referralDataArray);
                }
            } catch (error) {
                console.error('Error fetching referral data:', error);
            }
        };
    
        fetchData();
    }, []);

      

    const calculateEarnings = () => {
        // Calculate earnings from referrals based on percentages
        const currentUser = firebase.auth().currentUser;
        let totalEarnings = 0;
        refferalData.forEach((referral) => {
            if (referral.payments && referral.payments.length > 0) {
                const firstPayment = referral.payments[0];
                const remainingPayments = referral.payments.slice(1).reduce((acc, curr) => acc + curr, 0);
                const firstPaymentEarnings = firstPayment/1.02 * 0.02; // 2% for the first payment
                const remainingPaymentsEarnings = remainingPayments/1.02 * 0.02 *0.1; // 0.2% for subsequent payments
                
                // Ensure that userData.Rewarduse is not null and contains the expected data
                const usePrice = userData?.Rewarduse?.reduce((acc, item) => acc + item.usePrice, 0) ?? 0;
                
                const referralEarnings = firstPaymentEarnings + remainingPaymentsEarnings - usePrice;
                totalEarnings += referralEarnings;
    
                // Update earnPrice in Firestore
                const earnPriceRef = firebase.firestore().collection('users').doc(currentUser.uid);
                earnPriceRef.get()
                    .then((docSnapshot) => {
                        if (docSnapshot.exists) {
                            // Document exists, update it
                            earnPriceRef.update({
                                earnPrice: referralEarnings,
                            });
                        } else {
                            console.log('Document does not exist');
                        }
                    })
                    .catch((error) => {
                        console.error('Error checking document existence:', error);
                    });
    
                console.log(`Referral: ${referral.name}`);
                console.log(`Use Price: ${usePrice}`);
                console.log(`First Payment: ${firstPayment}`);
                console.log(`First Payment Earnings: ${firstPaymentEarnings}`);
                console.log(`Remaining Payments: ${remainingPayments}`);
                console.log(`Remaining Payments Earnings: ${remainingPaymentsEarnings}`);
                console.log(`Total Earnings for Referral: ${referralEarnings}`);
            }
        });
        console.log(`Total Earnings from Referrals: ${totalEarnings}`);
        return totalEarnings;
    };
    
    

  
  
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        const textField = document.createElement('textarea');
        textField.innerText = `https://equisculpt.in/signinsinup?refferal=${userData.userId}`;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        setCopied(true);
    
        // Reset the "Copied" state after a brief delay
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      };


    const shareReferral = async () => {
        try {
          await navigator.share({
            title: 'Share Referral Link',
            text: `Join me on Equisculpt and earn rewards!`,
            url: `https://equisculpt.in/signinsinup/?refferal=${userData.userId}`
          });
        } catch (error) {
          console.error('Sharing failed', error);
        }
      };



    const handleLogout = () => {
        firebase.auth().signOut().then(() => {
            // Sign out successful, navigate to home page
            navigate('/'); // Redirect to the home page
        }).catch((error) => {
            // Handle errors if any during logout
            console.log('Logout error:', error);
        });
    };

    const handleViewDocument = (url) => {
        window.open(url, '_blank');
    };
    
    return (
        
        <div>

{userData ? (
            <><PageTitle heading='User Profile' title='User' /><section className="user-profile flat-tabs">

                    <div className="container">
                        <div className="row">
                            <Tabs>

                                <TabList>
                                    <div className="user-info center">
                                        <div className="avt">
                                            <input
                                                type="file"
                                                className="custom-file-input"
                                                id="imgInp"
                                                required />
                                            <img id="blah" src={userData.photo} alt="no file" />
                                        </div>
                                        <h6 className="name">{userData.name} </h6>
                                        <p>{userData.email}</p>
                                    </div>
                                    {dataCoinTab.map(idx => (
                                         <Tab key={idx.id} onClick={idx.title === 'Logout' ? handleLogout : null}><h6 className="fs-16">
                                            <i className={`fa ${idx.icon}`}></i>
                                            {idx.title}
                                        </h6></Tab>
                                    ))}

                                </TabList>

                                <TabPanel>
                                    <div className="content-inner profile">
                                        <form action="#">
                                            <h4>User Profile</h4>
                                            
                                            {userData.status === 'Active' ? (
                <h6 className='text-green-500 text-sm'> Your Account Verified</h6>
            ) : (
                <h6 className='text-yellow-500 text-sm'>Your Account  Verification is Under Process</h6>
            )}
                                            <div className="form-group d-flex s1">
                                                <input readOnly type="text" className="form-control" value={userData.name} />
                                            </div>
                                            <div className="form-group d-flex">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    id="exampleInputEmail1"
                                                    readOnly
                                                    value={userData.email} />
                                                <div className="sl">
                                                    <select
                                                        className="form-control"
                                                        id="exampleFormControlSelect1"
                                                    >
                                                        <option>+91</option>
                                                    </select>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={userData.phoneNumber}
                                                        readOnly
                                                        placeholder="Your Phone number" />
                                                </div>
                                            </div>

                                            <div className="form-group d-flex">
                                            <textarea
  className="form-control"
  id="address"
  value={userData.address}
  readOnly // Ensure the textarea is read-only to display data
/>



                                             
                                            </div>
                                            <div class='relative flex-col justify-center overflow-hidden bg-gray-50 '>
  <div class="flex items-center justify-between border-b bg-blue-400 p-3">
   
    <div class="text-lg font-bold text-gray-100">Aadhar Card</div>
    <div class="flex items-center space-x-5 text-gray-100">
     
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
      <svg onClick={() => handleViewDocument(userData.aadharCardUrl)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 cursor-pointer">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
    </div>
  </div>
</div>
                                            <div class='relative flex-col justify-center overflow-hidden bg-gray-50 '>
  <div class="flex items-center justify-between border-b bg-blue-400 p-3">
   
    <div class="text-lg font-bold text-gray-100">Pan Card Card</div>
    <div class="flex items-center space-x-5 text-gray-100">
     
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 cursor-pointer">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
      <svg onClick={() => handleViewDocument(userData.panCardUrl)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 cursor-pointer">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
    </div>
  </div>
</div>
                                            <div class='relative flex-col justify-center overflow-hidden bg-gray-50 '>
  <div class="flex items-center justify-between border-b bg-blue-400 p-3">
   
    <div class="text-lg font-bold text-gray-100">CMR Copy</div>
    <div class="flex items-center space-x-5 text-gray-100">
     
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
      <svg onClick={() => handleViewDocument(userData.cmrcopyUrl)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 cursor-pointer">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
    </div>
  </div>
</div>
                                            <div class='relative flex-col justify-center overflow-hidden bg-gray-50 '>
  <div class="flex items-center justify-between border-b bg-blue-400 p-3">
   
    <div class="text-lg font-bold text-gray-100">Cancelled Check</div>
    <div class="flex items-center space-x-5 text-gray-100">
     
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm6.905 9.97a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72V18a.75.75 0 001.5 0v-4.19l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
      <svg onClick={() => handleViewDocument(userData.cancelledcheckUrl)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6 cursor-pointer ">
        <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clip-rule="evenodd" />
        <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
      </svg>
    </div>
  </div>
</div>
                                 
                                        </form>
                                    </div>
                                </TabPanel>

                                <TabPanel>
                                {userData.status === 'Active' ? (
                                    <div className="content-inner referrals">
                                        <h6>Total rewards available from referrals: ₹{calculateEarnings().toFixed(2)}</h6>
                                        {/* {userData && userData.Rewarduse && userData.Rewarduse.length > 0 ? (
        userData.Rewarduse.map((item, index) => (
            <h6 key={index}>Referrals reward used: {item.usePrice}</h6>
        ))
    ) : (
        <div>No Rewarduse data</div>
    )} */}
                                       
                                        {/* <h4>$1,056.00 <span>USD</span></h4> */}
                                      
                                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-4">
                <thead className="text-xs text-white uppercase bg-lime-600 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="py-3 px-6">No. of Referrals</th>
                        <th scope="col" className="py-3 px-6">1st Investment of Referral</th>
                        <th scope="col" className="py-3 px-6">Lifetime Reward</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Display data for up to 5 referrals */}
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="py-4 px-6">Up to 5</td>
                        <td className="py-4 px-6">100% of transaction charge</td>
                        <td className="py-4 px-6">0</td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="py-4 px-6">5 & above</td>
                        <td className="py-4 px-6">100% of transaction charge</td>
                        <td className="py-4 px-6">10% of transaction charge</td>
                    </tr>
                    {/* Display additional rows if needed */}
                </tbody>
            </table>
                                        <div className="main">
                                            <h6>Invite friends to earn 10%</h6>

                                            <div className="refe">
                                                <div>
                                                    <p>Referral link</p>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        readOnly
                                                        value={`https://equisculpt.in/signinsinup/?refferal=${userData.userId}`}/>
                                                            <span className="btn-action" onClick={copyToClipboard}>
        {copied ? 'Copied' : 'Copy'}
      </span>
                                                </div>
                                                {/* <div>
                                                    <p>Referral code</p>
                                                    <input
                                                    readOnly
                                                        className="form-control"
                                                        type="text"
                                                        value={userData.userId} />
                                                     <span className="btn-action" onClick={copyToClipboard}>
        {copied ? 'Copied' : 'Copy'}
      </span>
                                                </div> */}
                                            </div>
                                        </div>

                                          <span className="btn-action" onClick={shareReferral}>
        Share Referral
      </span>

      <h1 className='text-xl font-bold text-center text-blue-900 mb-8'>Your Refferal</h1>
      <span className='text-lg font-bold text-center text-green-900 '>*Refferal counted only on successful 1st investment*</span>
      <div class="   items-center mt-4 justify-center ">
        <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="py-3 px-6">Name</th>
                    <th scope="col" class="py-3 px-6">Approved</th>
                    <th scope="col" class="py-3 px-6">Total Invested</th>
                </tr>
                </thead>
                <tbody>
    {refferalData.map((referral) => (
        <tr key={referral.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <td className="py-4 px-6">{referral.name}</td>
            <td className="py-4 px-6">
                {referral.status === 'Active' ? (
                    <h6 className='text-green-500 text-sm'>Yes</h6>
                ) : (
                    <h6 className='text-yellow-500 text-sm'>No</h6>
                )}
            </td>
            <tr>
    <td className="py-4 px-6">
        {referral.payments && referral.payments.length > 0 ? (
            referral.payments.map((payment, index) => (
                <div key={index}>{payment}</div>
            ))
        ) : (
            <div>No payments</div>
        )}
    </td>
</tr>

        </tr>
    ))}
</tbody>

            </table>
            </div>
    </div>
    
</div>


                                    </div>
                                     ) : (
                                        <h6 className='text-yellow-500 text-sm'>Your Account  Verification is Under Process</h6>
                                    )}
                                </TabPanel>
                                <TabPanel>
                                {userData.status === 'Active' ? (
                                    <div className="content-inner referrals">
                                        <h6>Total rewards available from referrals</h6>
                          
  


                                    </div>
                                     ) : (
                                        <h6 className='text-yellow-500 text-sm'>Your Account  Verification is Under Process</h6>
                                    )}
                                </TabPanel>











                                <TabPanel>
                                    <div className="content-inner api">
                                        <h6>Enable API access on your account to generate keys.</h6>
                                        <h4>API Access is <span>Disabled</span></h4>
                                        <p className="mail">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M20 5H4C3.44772 5 3 5.44772 3 6V18C3 18.5523 3.44772 19 4 19H20C20.5523 19 21 18.5523 21 18V6C21 5.44772 20.5523 5 20 5ZM4 3C2.34315 3 1 4.34315 1 6V18C1 19.6569 2.34315 21 4 21H20C21.6569 21 23 19.6569 23 18V6C23 4.34315 21.6569 3 20 3H4Z"
                                                    fill="#23262F" />
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M5.2318 7.35984C5.58537 6.93556 6.21593 6.87824 6.64021 7.2318L11.3598 11.1648C11.7307 11.4739 12.2694 11.4739 12.6402 11.1648L17.3598 7.2318C17.7841 6.87824 18.4147 6.93556 18.7682 7.35984C19.1218 7.78412 19.0645 8.41468 18.6402 8.76825L13.9206 12.7013C12.808 13.6284 11.192 13.6284 10.0795 12.7013L5.35984 8.76825C4.93556 8.41468 4.87824 7.78412 5.2318 7.35984Z"
                                                    fill="#23262F" />
                                            </svg>
                                            petersonkenn@demo.com
                                        </p>
                                        <div className="main">
                                            <h6>Enable API keys</h6>
                                            <p>Enter your password and 2FA code to Enable the API keys</p>

                                            <div className="refe">
                                                <div className="form-group">
                                                    <p>Your Password</p>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        placeholder="Passworld" />
                                                </div>
                                                <div className="form-group">
                                                    <p>2FA Code</p>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="2FA code" />
                                                </div>
                                            </div>
                                            <Link to="#" className="btn-action">Enable API keys</Link>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="content-inner api">
                                        <h4>2FA <span className="color-success">Enabled</span></h4>
                                        <p>
                                            If you want to turn off 2FA, input your account password and
                                            the six-digit code provided by the Google Authenticator app
                                            below, then click <strong>"Disable 2FA"</strong>.
                                        </p>

                                        <div className="main">
                                            <h6>Disable 2FA</h6>
                                            <p>
                                                Enter your password and 2FA code to Disable the 2FA
                                                verification
                                            </p>

                                            <div className="refe">
                                                <div className="form-group">
                                                    <p>Your Password</p>
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        placeholder="Passworld" />
                                                </div>
                                                <div className="form-group">
                                                    <p>2FA Code</p>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="2FA code" />
                                                </div>
                                            </div>
                                            <Link to="#" className="btn-action">Disable 2FA verification</Link>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="content-inner profile change-pass">
                                        <h4>Change Password</h4>
                                        <h6>New Passworld</h6>
                                        <form action="#">
                                            <div className="form-group">
                                                <div>
                                                    <label>Old Passworld<span>*</span>:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value="123456789" />
                                                </div>
                                                <div>
                                                    <label>2FA Code<span>*</span>:</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div>
                                                    <label>New Passworld<span>*</span>:</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="New Passworld" />
                                                </div>
                                                <div>
                                                    <label>Confirm Passworld<span>*</span>:</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Confirm Passworld" />
                                                </div>
                                            </div>
                                        </form>
                                        <button type="submit" className="btn-action">
                                            Change Passworld
                                        </button>
                                    </div>
                                </TabPanel>


                            </Tabs>
                        </div>
                    </div>
                </section>
                {/* <Sale01 /> */}
                </>
            ) : (
                <div class="flex justify-center items-center h-screen">
  <div class="rounded-full h-20 w-20 bg-green-800 animate-ping"></div>
</div>
              )}
            
        </div>
    );
}

export default UserProfile;